<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="text-center mt-4 mb-4">
                    <h1 class="display-4">Terms And Conditions</h1>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">General Information</h5>
                        <p>By downloading or using the app, these terms will automatically apply to you – you should make
                            sure therefore that you read them carefully before using the app. You’re not allowed to copy or
                            modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt
                            to extract the source code of the app, and you also shouldn’t try to translate the app into
                            other languages or make derivative versions. The app itself, and all the trademarks, copyright,
                            database rights, and other intellectual property rights related to it, still belong to Florian
                            Zopf.</p>
                        <p>Florian Zopf is committed to ensuring that the app is as useful and efficient as possible. For
                            that reason, we reserve the right to make changes to the app or to charge for its services, at
                            any time and for any reason. We will never charge you for the app or its services without making
                            it very clear to you exactly what you’re paying for.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Data Storage and Security</h5>
                        <p>The Qwik VBT app stores and processes personal data that you have provided to us, to provide my
                            Service. It’s your responsibility to keep your phone and access to the app secure. We therefore
                            recommend that you do not jailbreak or root your phone, which is the process of removing
                            software restrictions and limitations imposed by the official operating system of your device.
                            It could make your phone vulnerable to malware/viruses/malicious programs, compromise your
                            phone’s security features and it could mean that the Qwik VBT app won’t work properly or at all.
                        </p>
                        <p>In order to provide the online mode feature, we store user data in the cloud. We take the privacy
                            and security of your data very seriously and have implemented industry-standard measures to
                            protect your data from unauthorized access, disclosure, alteration, and destruction. However,
                            please be aware that no method of electronic storage or transmission over the Internet is 100%
                            secure, and we cannot guarantee the absolute security of your data.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Account and Authentication</h5>
                        <p>Users have the option to log in to our app using their Google or Apple accounts. If you choose to
                            log in this way, we will access certain information from your Google or Apple account, such as
                            your name and email address, to create and authenticate your account. We do not store your
                            Google or Apple login credentials. The data we access is used solely for account creation and
                            authentication purposes and is protected in accordance with our data protection measures.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Online Mode and Cloud Storage</h5>
                        <p>The online mode allows users to save exercise set data and videos in the cloud, making it
                            accessible from any device. When you use the online mode, we collect and store this data in the
                            cloud. This data is used solely for the purpose of providing the online mode feature and is
                            protected in accordance with our data protection measures.</p>
                        <p>By using the online mode, you consent to our collection, storage, and use of your exercise set
                            data and videos in the cloud. You can withdraw your consent at any time by turning off the
                            online mode feature in the app settings. Please note that withdrawing consent will result in the
                            loss of access to data saved in the cloud.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Third-Party Services</h5>
                        <p>The app does use third-party services that declare their Terms and Conditions.</p>
                        <ul>
                            <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google
                                    Play Services</a></li>
                        </ul>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Responsibility and Usage</h5>
                        <p>You should be aware that there are certain things that Florian Zopf will not take responsibility
                            for. Certain functions of the app will require the app to have an active internet connection.
                            The connection can be Wi-Fi or provided by your mobile network provider, but Florian Zopf cannot
                            take responsibility for the app not working at full functionality if you don’t have access to
                            Wi-Fi, and you don’t have any of your data allowance left.</p>
                        <p>If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the
                            agreement with your mobile network provider will still apply. As a result, you may be charged by
                            your mobile provider for the cost of data for the duration of the connection while accessing the
                            app, or other third-party charges. In using the app, you’re accepting responsibility for any
                            such charges, including roaming data charges if you use the app outside of your home territory
                            (i.e. region or country) without turning off data roaming. If you are not the bill payer for the
                            device on which you’re using the app, please be aware that we assume that you have received
                            permission from the bill payer for using the app.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Instructions & Optimal Functionality</h5>
                        <p>
                            It is crucial for users to follow the instructions provided within the app to ensure its optimal
                            functionality.
                        </p>
                        <p>
                            Failure to adhere to the instructions may result in inaccurate results and the app may not
                            perform as intended.
                            Florian Zopf cannot be held responsible for any issues or inaccuracies that arise due to the
                            user not following
                            the app's instructions.
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">App Updates and Modifications</h5>
                        <p>At some point, we may wish to update the app. The app is currently available on Android & iOS –
                            the requirements for both systems (and for any additional systems we decide to extend the
                            availability of the app to) may change, and you’ll need to download the updates if you want to
                            keep using the app. Florian Zopf does not promise that it will always update the app so that it
                            is relevant to you and/or works with the Android & iOS version that you have installed on your
                            device. However, you promise to always accept updates to the application when offered to you, We
                            may also wish to stop providing the app, and may terminate use of it at any time without giving
                            notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights
                            and licenses granted to you in these terms will end; (b) you must stop using the app, and (if
                            needed) delete it from your device.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Data Storage Duration and Account Activity</h5>
                        <p>We value the security and privacy of our users' data. To ensure optimal server performance and
                            data management, any account that remains inactive for a period of six (6) months may be subject
                            to data deletion. "Inactive" refers to accounts that have not been logged into or accessed
                            within the specified period.

                            As a courtesy to our users, we will send a notification email at least one (1) month prior to
                            any potential data deletion due to inactivity. This email will remind users to log in to their
                            account to prevent data removal. It is the user's responsibility to ensure that they check their
                            email regularly and take appropriate action to maintain account activity.

                            We encourage users to log in periodically to avoid any unintentional loss of data. By using our
                            app, users acknowledge and agree to this data storage duration and account activity policy.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Service Discontinuation and Data Loss</h5>
                        <p>We reserve the right to modify, suspend, or discontinue the service at any time, with or without
                            notice. As this is a hobby project and provided as a free service, there are no guarantees
                            regarding the continuity or availability of the service. In the event of discontinuation, data
                            associated with user accounts may be permanently lost. We will make reasonable efforts to
                            provide prior notice to users in such scenarios, but cannot guarantee it.

                            By using our app, users acknowledge and accept the risks associated with the potential
                            discontinuation of the service and the possible loss of data. We encourage users to regularly
                            back up or export any important data they have stored within the app to prevent unintentional
                            loss.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Changes to This Terms and Conditions</h5>
                        <p>We reserve the right to modify, suspend, or discontinue the service at any time, with or without
                            notice. As this is a hobby project and provided as a free service, there are no guarantees
                            regarding the continuity or availability of the service. In the event of discontinuation, data
                            associated with user accounts may be permanently lost. We will make reasonable efforts to
                            provide prior notice to users in such scenarios, but cannot guarantee it.

                            By using our app, users acknowledge and accept the risks associated with the potential
                            discontinuation of the service and the possible loss of data. We encourage users to regularly
                            back up or export any important data they have stored within the app to prevent unintentional
                            loss.</p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Contact Us</h5>
                        <p>If you have any questions or suggestions about my Terms and Conditions, do not hesitate to
                            contact me at qwikvbt@gmail.com.</p>
                </div>
            </div>
        </div>
    </div>
</div></template>
