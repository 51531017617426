<template>
    <div style="text-align: center; margin-top: 20px;">
        <h1>Email Verification</h1>
        <p>Your email has been verified. You can now login.</p>
    </div>
</template>
  
<script>
export default {
    mounted() {
        this.removeAllAttributesFromURL();
    },
    methods: {
        removeAllAttributesFromURL() {
            if (window.location.hash) {
                // Remove everything after '#' in the URL
                window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
            }
        }
    }
};
</script>
  