<!-- App.vue -->

<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
    </metainfo>
    <router-view></router-view> <!-- This is where the content for the current route will be displayed -->
</template>
  
<script>
// import { useMeta } from 'vue-meta';

export default {
    setup() {
        // useMeta({
        //     title: 'Your Title',
        //     // meta: [
        //     //     { name: 'description', content: 'Your Description' },
        //     //     // ... other meta tags
        //     // ]
        // });
    }
};
</script>
  
<style scoped>
/* Component-specific styles go here */
</style>
  